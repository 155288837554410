import { loadDefinition, loading } from "./strong.api";
import { toAppStrongModel } from "./strong.models";

export function useStrong() {

    async function fetchDefinition({ strongCode, strongLocale }: any) {
        const definition = await loadDefinition({ strongCode, strongLocale });
        //create strong models
        return toAppStrongModel({ definition });
    }

    return {
        fetchDefinition,
        loading,
    }
}
