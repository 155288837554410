import { NUXT_APP_BIBLE_API } from "~/env";
import { useAxios } from "../useAxios";

const { axios, loading } = useAxios();

const bibleApi = NUXT_APP_BIBLE_API;

async function loadDefinition({ strongCode, strongLocale }: any) {
    const params = {
        strongCode,
        strongLocale
    };
    const { definition } = await axios.post(bibleApi + `/strong/lookup`, params) || {}

    return definition;
}

export {
    loadDefinition,
    loading
}