function toAppStrongModel({ definition: d }: any = {}) {
    if(!d){
        console.log('Strong defenitions not found');
        return;
    }

    const strongModel = {
        strongCode: d.strong_code,
        strongLang: d.strong_lang,
        strongDef: d.strong_def,
        strongLexeme: d.strong_lexeme,
        strongPronun: d.strong_pronun,
        strongShortDef: d.strong_short_def,
        strongTranslit: d.strong_translit
    }

    return strongModel;
}

export {
    toAppStrongModel,
}